<template>
  <v-list-item v-bind="$attrs" @click="onChange">
    <template v-if="sCode == 'cheque'">
      <v-list-item-content>
        <v-list-item-title v-text="sBankName" />
        <v-list-item-subtitle v-text="$t('bank')" />
      </v-list-item-content>

      <v-list-item-content v-if="obConfig">
        <v-list-item-title v-text="obConfig.holder" />
        <v-list-item-subtitle v-text="$t('holder')" />
      </v-list-item-content>
    </template>

    <template v-if="sCode == 'tarjeta'">
      <v-list-item-content v-if="obConfig">
        <v-list-item-title v-text="obConfig.holder" />
        <v-list-item-subtitle v-text="$t('holder')" />
      </v-list-item-content>

      <v-list-item-content>
        <v-list-item-title v-text="sMaskedNumber" />
        <v-list-item-subtitle v-text="$t('number')" />
      </v-list-item-content>
    </template>

    <template v-if="sCode == 'cuenta_bancaria'">
      <v-list-item-content>
        <v-list-item-title v-text="sBankName" />
        <v-list-item-subtitle v-text="$t('bank')" />
      </v-list-item-content>

      <v-list-item-content>
        <v-list-item-title>
          {{ obConfig.account_type }}
          {{ obConfig.account_number }}
        </v-list-item-title>
        <v-list-item-subtitle v-text="$t('bank.account')" />
      </v-list-item-content>
    </template>
  </v-list-item>
</template>

<script lang="ts">
import type {
  InvoicePaymentMethodItemKey,
  PaymentMethodPivotData,
} from "@/config/invoicePaymentMethods";
import { maskCC } from "@/plugins/helpers";
import { AppModule } from "@/store/app";
import type { PaymentMethodData } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, Vue } from "vue-property-decorator";
import { get, isNaN } from "lodash";
import { number } from "mathjs";

@Component
export default class CompanyPaymentMethodItemPreview extends Vue {
  @Prop(Object) readonly paymentMethod!: PaymentMethodData;

  get obPivotData(): Partial<PaymentMethodPivotData> {
    return get(this.paymentMethod, "pivot", {});
  }

  get obConfig(): Record<InvoicePaymentMethodItemKey, any> | undefined {
    return get(this.obPivotData, "config");
  }

  get sCode() {
    return this.paymentMethod.code.toLowerCase();
  }

  get sBankName() {
    const sBank = get(this.obPivotData, "config.bank_name");
    let iBankId: number | undefined = get(this.obPivotData, "config.bank");
    if (!sBank && !iBankId) {
      return undefined;
    }

    if (sBank && isNaN(sBank)) {
      return sBank;
    } else if (!isNaN(sBank)) {
      iBankId = number(sBank);
    }

    const obBank = AppModule.banks.find({ id: iBankId });
    return obBank ? obBank.name : undefined;
  }

  get sMaskedNumber() {
    if (!this.obConfig) {
      return undefined;
    }

    const sNumber = this.obConfig.number;

    if (!sNumber) {
      return undefined;
    }

    return maskCC(sNumber);
  }

  onChange() {
    this.$emit("change", this.paymentMethod);
  }
}
</script>
