<template>
  <v-radio-group row v-model="sAccountType" v-if="radio">
    <template v-for="item in arItems">
      <v-radio
        :value="item.value"
        :label="$t(item.text)"
        :key="`bank-accounttype-${item.value}`"
      />
    </template>
  </v-radio-group>

  <v-select
    v-model="sAccountType"
    :items="arItems"
    :label="$t(label)"
    hide-details="auto"
    clearable
    outlined
    dense
    v-else
  ></v-select>
</template>

<script lang="ts">
import { Vue, Component, Prop, VModel } from "vue-property-decorator";

@Component
export default class BankAccountType extends Vue {
  @VModel({ type: String }) sAccountType!: string;
  @Prop({ type: String, default: "account.type" }) readonly label!: string;
  @Prop(Boolean) readonly radio!: boolean;

  arItems: Record<string, any>[] = [];

  mounted() {
    this.$nextTick(() => {
      this.arItems = [
        { text: this.$t("bank.account.type.cc"), value: "CC" },
        { text: this.$t("bank.account.type.ca"), value: "CA" },
      ];
    });
  }
}
</script>
